import { nosPurple } from "../colors/nosPurple";

export const ButtonStyle = {
  baseStyle: {
    // backgroundColor: purple[700], // Default background color
    // color: "white", // Text color
    "font-size": "14px !important",
    // fontWeight: "400",
    // _hover: {
    //   backgroundColor: purple[800], // Background color on hover
    // },
    // _active: {
    //   backgroundColor: purple[900], // Background color when active
    // },
  },
  variants: {
    custom: {
      bg: "black",
      color: "white",
      _hover: {
        backgroundColor: nosPurple[800],
      },
    },
  },
};
