import { CredentialResponse } from "google-one-tap";
import { useEffect, useState } from "react";
import { useStatics } from "../api/modules/Statics";

type Props = {
  callback: (credentialResponse: CredentialResponse) => void;
  context?: "signin" | "signup" | "use";
};
export const useInitializeGoogleAuth = (props: Props) => {
  const { callback, context } = props;
  const { isLoading: isLoadingStatics, data: staticsData } = useStatics();

  const [buttonWidth, setButtonWidth] = useState<number>(400);
  const calculateButtonWidth = () => {
    const viewportWidth = window.innerWidth;
    const newWidth = Math.min(viewportWidth - 30, 400);
    setButtonWidth(newWidth);
  };
  useEffect(() => {
    calculateButtonWidth();
    window.addEventListener("resize", calculateButtonWidth);
    return () => window.removeEventListener("resize", calculateButtonWidth);
  }, []);

  useEffect(() => {
    if (typeof google === "undefined" || isLoadingStatics) return;
    const googleBtn = document.getElementById("google-button");
    google.accounts.id.initialize({
      client_id: staticsData?.googleClientId || "",
      callback,
      context: context || "signin",
    });

    if (googleBtn) {
      if (context === "signup") {
        google.accounts.id.renderButton(googleBtn, {
          width: buttonWidth,
          logo_alignment: "center",
          text: "signup_with",
        });
      } else {
        google.accounts.id.renderButton(googleBtn, {
          width: buttonWidth,
          logo_alignment: "left",
        });
      }
    }
    google.accounts.id.prompt();
  }, [buttonWidth, callback, context, isLoadingStatics, staticsData]);
};
