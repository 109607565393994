import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Routing } from "../../../../constants/routing";
import { PlanPresentation } from "./Plan.presentation";
// import { useGetStripeLink } from "../../../../api/modules/GetStripeLink";
import { Props } from "./Plan.types";

export const Plan = (props: Props) => {
  const { data } = props;
  const navigate = useNavigate();

  const handleManagePlan = useCallback(async () => {
    navigate(Routing.PayWall);
  }, [navigate]);

  return <PlanPresentation data={data} handleManagePlan={handleManagePlan} />;
};
