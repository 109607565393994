import React, { createContext, ReactNode } from "react";
import { useDisclosure } from "@chakra-ui/react";

type Props = {
  children: ReactNode;
};

export type TModalControls = {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
};

export type ModalsContextType = {
  inviteTeamMember: TModalControls;
  assignJob: TModalControls;
  updateRoles: TModalControls;
  removeFromTeam: TModalControls;
  cancelJob: TModalControls;
  createManifest: TModalControls;
  createTruckModal: TModalControls;
  startupModal: TModalControls;
  makeOfferModal: TModalControls;
};

export const ModalsContext = createContext<ModalsContextType>(
  {} as ModalsContextType
);

export const ModalsContextWrapper = (props: Props) => {
  const { children } = props;
  const inviteTeamMember = useDisclosure();
  const assignJob = useDisclosure();
  const updateRoles = useDisclosure();
  const removeFromTeam = useDisclosure();
  const cancelJob = useDisclosure();
  const createManifest = useDisclosure();
  const createTruckModal = useDisclosure();
  const startupModal = useDisclosure();
  const makeOfferModal = useDisclosure();

  return (
    <ModalsContext.Provider
      value={{
        inviteTeamMember,
        assignJob,
        updateRoles,
        removeFromTeam,
        cancelJob,
        createManifest,
        createTruckModal,
        startupModal,
        makeOfferModal,
      }}
    >
      {children}
    </ModalsContext.Provider>
  );
};
