import React, { useCallback, useRef, useState } from "react";
import {
  Avatar,
  Flex,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
  Text,
  Tooltip,
  Image,
  Box,
  VStack,
  Button,
  useToast,
} from "@chakra-ui/react";
import { Props } from "./ContactInformation.types";
import { t } from "i18next";
import { translations } from "../../../../i18n/translations";
import { useUploadProfilePhoto } from "../../../../api/modules/UploadProfilePhoto";
import { getProfileImageUrl } from "../../../../utils/getProfileImageUrl";
import { FiHelpCircle } from "react-icons/fi";
import AvatarImg from "../../../../assets/avatar.png";
import UploadImg from "../../../../assets/upload-icon.png";

export const ContactInformation = (props: Props) => {
  const screen = translations.screens.profile;
  const { isUpdatingProfile, isEditMode, data, onEditField, formData } = props;
  const { firstName, lastName, email, profilePicUrl } = data || {};
  const { mutateAsync: handleUploadProfilePhoto } = useUploadProfilePhoto();
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [profile64PicUrl, setProfile64PicUrl] = useState<string | null>(null); // set null initially

  const toast = useToast();

  const handleFileChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (!file) {
        console.error("No file selected");
        return;
      }
      const allowedTypes = [
        "image/svg+xml",
        "image/png",
        "image/jpeg",
        "image/gif",
      ];
      if (!allowedTypes.includes(file.type)) {
        toast({
          title: "Unsupported file type.",
          description: "Please upload an SVG, PNG, JPG, or GIF file.",
          status: "warning",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        return;
      }

      try {
        const reader = new FileReader();
        reader.onloadend = async () => {
          const base64StringImage = (reader.result as string).split(",")[1];
          setProfile64PicUrl(`data:image/*;base64,${base64StringImage}`);
          const response = await handleUploadProfilePhoto({
            base64StringImage,
          });
          if (!response) {
            throw new Error("No response from server");
          }
        };
        reader.readAsDataURL(file);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    },
    [handleUploadProfilePhoto, toast]
  );

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files?.[0];
    if (!file) {
      console.error("No file was dropped");
      return;
    }
    if (!inputFileRef.current) {
      console.error("Input file ref is not initialized");
      return;
    }
    inputFileRef.current.files = e.dataTransfer.files;
    handleFileChange({
      target: inputFileRef.current,
    } as React.ChangeEvent<HTMLInputElement>);
  };

  // Ensure you also prevent the default behavior on drag over
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  return (
    <TableContainer maxWidth="100%" width="100%">
      <Table variant="simple">
        <Tbody fontSize="sm">
          <Tr>
            <Td
              fontWeight={600}
              paddingLeft={0}
              verticalAlign="text-top"
              width="280px"
            >
              {t<string>(screen.table.name)}
            </Td>
            <Td color="gray.500">
              <Flex gap="24px">
                {isEditMode ? (
                  <Input
                    disabled={isUpdatingProfile}
                    value={formData.firstName}
                    width="244px"
                    onChange={onEditField("firstName")}
                  />
                ) : (
                  firstName || "-"
                )}{" "}
                {isEditMode ? (
                  <Input
                    disabled={isUpdatingProfile}
                    value={formData.lastName}
                    width="244px"
                    onChange={onEditField("lastName")}
                  />
                ) : (
                  lastName || "-"
                )}
              </Flex>
            </Td>
          </Tr>
          {/* <Tr>
            <Td fontWeight={500} paddingLeft={0} width="280px">
              {t<string>(screen.table.phone)}
            </Td>
            <Td color="gray.500">
              {isEditMode && (
                <PatternFormat
                  customInput={Input}
                  defaultValue={formData.phoneNumber}
                  disabled={isUpdatingProfile}
                  format={PHONE_PATTERN}
                  width="512px"
                  onValueChange={(v) => {
                    onEditField("phoneNumber")({
                      target: { value: v.value },
                    } as any);
                  }}
                />
              )}
              {!isEditMode && (
                <PatternFormat
                  displayType="text"
                  format={PHONE_PATTERN}
                  value={phoneNumber}
                  width="512px"
                />
              )}
            </Td>
          </Tr> */}
          <Tr>
            <Td
              fontWeight={600}
              paddingLeft={0}
              paddingY="24px"
              verticalAlign="text-top"
              width="280px"
            >
              {t<string>(screen.table.email)}
            </Td>
            <Td color="gray.500">{email || "-"}</Td>
          </Tr>
          <Tr>
            <Td verticalAlign="text-top" width="280px">
              <Flex flexDirection="column">
                <Flex flexDirection="row" gap="2px">
                  <Text fontSize="14px" fontWeight={600} marginLeft="-24px">
                    {t<string>(screen.table.photo)}
                  </Text>
                  <Flex alignItems="center">
                    <Tooltip label="Upload a photo">
                      <span>
                        <FiHelpCircle />
                      </span>
                    </Tooltip>
                  </Flex>
                </Flex>
                <Text fontSize="14px" marginLeft="-24px">
                  {t<string>(screen.table.photoDescription)}
                </Text>
              </Flex>
            </Td>
            <Td color="gray.500">
              <Flex flexDirection="row" gap="20px">
                <Flex>
                  <Avatar
                    bg="gray.200"
                    height="64px"
                    icon={<Image src={AvatarImg} />}
                    src={
                      profile64PicUrl || getProfileImageUrl({ profilePicUrl })
                    } // show uploaded or existing image
                    width="64px"
                    onClick={() => inputFileRef.current?.click()}
                  />
                </Flex>
                <Flex>
                  <Input
                    ref={inputFileRef}
                    accept="image/svg+xml,image/png,image/jpeg,image/gif" // Restrict file types
                    display="none"
                    type="file"
                    onChange={handleFileChange}
                  />
                  <Box
                    _hover={{ borderColor: "#CBD5E0" }}
                    border="1px"
                    borderColor="inherit"
                    borderRadius="md"
                    cursor="pointer"
                    height="126px"
                    p={6}
                    textAlign="center"
                    width="428px"
                    onClick={() => inputFileRef.current?.click()}
                    onDragOver={handleDragOver} // Add this to prevent default behavior
                    onDrop={handleDrop}
                  >
                    <VStack alignContent="start" marginTop="-10px" spacing={1}>
                      <Image src={UploadImg} width="40px" />
                      <Text color="gray.500">
                        <Button
                          colorScheme="nosPurple"
                          variant="link"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevents the Box's onClick from being triggered
                            inputFileRef.current?.click(); // Opens the file input
                          }}
                        >
                          Click to upload
                        </Button>{" "}
                        or drag and drop
                      </Text>
                      <Text color="gray.500">
                        SVG, PNG, JPG or GIF (max. 800x400px)
                      </Text>
                    </VStack>
                  </Box>
                </Flex>
              </Flex>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};
