import React from "react";
import {
  Flex,
  TableContainer,
  Tbody,
  Table,
  Tr,
  Td,
  Text,
  Button,
} from "@chakra-ui/react";
import { t } from "i18next";
import { PresentationProps } from "./Plan.types";
import { translations } from "../../../../i18n/translations";
import { format } from "date-fns";
import { DATE_FORMAT } from "../../../../constants/global";

export const PlanPresentation = (props: PresentationProps) => {
  const screen = translations.screens.settings.plan;
  const { data, handleManagePlan } = props;
  const plan = data?.companySubscription;

  return (
    <TableContainer maxWidth="100%" width="100%">
      <Table variant="simple">
        <Tbody fontSize="sm">
          <Tr>
            <Td paddingLeft={0} verticalAlign="text-top" width="280px">
              <Text fontWeight={600}>{plan?.planName}</Text>
              {plan?.productId === "free_trial" ? (
                <>
                  <Text>Upgrade to unlock powerful </Text>
                  <Text>features</Text>
                </>
              ) : (
                <Text>{plan?.formattedPrice}</Text>
              )}
            </Td>
            <Td color="gray.500">
              <Flex gap="24px">
                <Button
                  colorScheme="nosPurple"
                  isDisabled={!!(plan && plan.productId !== "free_trial")}
                  size="lg"
                  variant="outline"
                  onClick={() => handleManagePlan()}
                >
                  {t<string>(screen.managePlan)}
                </Button>
              </Flex>
            </Td>
          </Tr>
          <Tr>
            <Td
              fontWeight={600}
              paddingLeft={0}
              verticalAlign="text-top"
              width="280px"
            >
              {t<string>(screen.renewOn)}
            </Td>
            <Td fontSize="16px" fontWeight={400}>
              <Flex gap="24px">
                {plan?.endDate && format(plan?.endDate, DATE_FORMAT)}
              </Flex>
            </Td>
          </Tr>

          <Tr>
            <Td
              fontWeight={600}
              paddingLeft={0}
              paddingY="24px"
              verticalAlign="text-top"
              width="280px"
            >
              {t<string>(screen.paymentMethod)}
            </Td>
            <Td color="gray.500">-</Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};
