import React, { useCallback, useContext, useRef, useState } from "react";
import { ModalsContext } from "../../../../contexts/ModalsContext";
import { CreateManifestModalPresentation } from "./CreateManifestModal.presentation";
import { CreateManifestSteps } from "./CreateManifestModal.types";
import { useCreateManifest } from "../../../../api/modules/CreateManifest";
import { Button, Flex, useToast } from "@chakra-ui/react";
import { IoCheckmarkCircleOutline as CheckCircle } from "react-icons/io5";
import { useUser } from "../../../../api/modules/User";

export const CreateManifestModal = () => {
  const { createManifest } = useContext(ModalsContext);
  const toast = useToast();
  const { data: userData } = useUser();
  const [step, setStep] = useState<CreateManifestSteps>(
    CreateManifestSteps.Details
  );

  const { mutateAsync: handleRequestCreateManifest, isLoading } =
    useCreateManifest();

  const openPdfInNewTab = useCallback((fileUrl?: string) => {
    if (fileUrl) {
      window.open(fileUrl, "_blank");
    }
  }, []);

  const handleCloseModal = useCallback(() => {
    createManifest.onClose();
    setStep(CreateManifestSteps.Details);
  }, [createManifest]);

  const handleDismissToast = useCallback(
    (id: string) => {
      toast.close(id);
    },
    [toast]
  );

  const toastIdRef = useRef<string | number | null>(null);
  const showProfilesaveToast = useCallback(
    (fileUrl?: string) => {
      const id = "manifestCreated";
      if (!toast.isActive(id)) {
        toastIdRef.current = toast({
          id,
          title: "Log Created",
          description: (
            <Flex alignSelf="flex-start" flexDirection="column">
              <Flex gap={1} justifyContent="flex-start" ml="-7px" mt={3}>
                <Button
                  color="green"
                  size="xs"
                  variant="ghost"
                  onClick={() => handleDismissToast(id)}
                >
                  Dismiss
                </Button>
                <Button
                  color="green"
                  size="xs"
                  variant="ghost"
                  onClick={() => openPdfInNewTab(fileUrl)} // Use the fileUrl directly
                >
                  View Log
                </Button>
              </Flex>
            </Flex>
          ),
          position: "top-right",
          status: "success",
          isClosable: true,
          duration: 10000,
          variant: "lightGreen",
          icon: <CheckCircle color="#067647" size="20px" />,
          containerStyle: {
            color: "#067647",
            background: "#F6FEF9",
            border: "1px solid #75E0A7",
            borderRadius: "6px",
          },
        });
      }
    },
    [toast, handleDismissToast, openPdfInNewTab]
  );

  const handleCreateManifest = useCallback(
    (startDate: string, endDate: string) => {
      handleRequestCreateManifest(
        {
          companyId: userData?.companyId,
          startDate,
          endDate,
        },
        {
          onSuccess: (data) => {
            showProfilesaveToast(data?.reportFileUrl);
            createManifest.onClose();
          },
        }
      );
    },
    [
      createManifest,
      handleRequestCreateManifest,
      showProfilesaveToast,
      userData?.companyId,
    ]
  );

  return (
    <CreateManifestModalPresentation
      isLoading={isLoading}
      isOpen={createManifest.isOpen}
      step={step}
      onClose={handleCloseModal}
      onCreateManifest={handleCreateManifest}
    />
  );
};
