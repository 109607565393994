import React, {
  ChangeEvent,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AvailableJobsPresentation } from "./AvailableJobs.presentation";
import { PortalsContext } from "../../../contexts/PortalsContext";
import { useStatics } from "../../../api/modules/Statics";
import { usePagination } from "../../../hooks/usePagination";
import { useAvailableJobs } from "../../../api/modules/AvailableJobs";
import { DISTANCE_OPTIONS } from "./constants";
import { AssignJob } from "../../AssignJob";
import { ModalsContext } from "../../../contexts/ModalsContext";
import debounce from "lodash/debounce";
import { Modes } from "./AvailableJobs.types";
import { DEFAULT_ITEMS_PER_PAGE } from "../../../constants/pagination";
import { useUser } from "../../../api/modules/User";
import { MakeOfferModal } from "./components/MakeOfferModal";
import { MakeOfferFormType } from "./components/MakeOfferModal/MakeOfferModal.types";

export const AvailableJobs = () => {
  const { pageTitleRef, breadcrumbsRef } = useContext(PortalsContext);
  const pagination = usePagination();
  const {
    data: userData,
    isLoading: isLoadingProfile,
    isFetched: isUserFetched,
  } = useUser();
  const [mode, setMode] = useState(Modes.Table);
  const [currentJob, setCurrentJob] = useState<number | undefined>(undefined);
  const { assignJob, makeOfferModal } = useContext(ModalsContext);
  // const { makeOfferModal } = useContext(ModalsContext);
  const [jobTypeId, setJobTypeId] = useState<number | undefined>(undefined);
  const [zip, setZip] = useState<string>("");
  const [distance, setDistance] = useState(DISTANCE_OPTIONS[0].value);

  const [makeOffer, setMakeOffer] = useState<MakeOfferFormType | undefined>(
    undefined
  );

  const { isLoading: isLoadingStatics, data: staticsData } = useStatics();
  const { isLoading: isLoadingJobs, data: jobsData } = useAvailableJobs({
    itemsPerPage: pagination.itemsPerPage,
    currentPage: pagination.currentPage,
    jobTypeId,
    lat: userData?.company?.lat,
    lng: userData?.company?.lng,
    miles: distance,
    zip,
    isUserFetched,
  });
  useEffect(() => {
    pagination.setTotalItems(jobsData?.totalJobsCount ?? 0);
  }, [pagination, jobsData?.totalJobsCount]);
  const handleChangeJobType = useCallback(
    (e: SyntheticEvent<HTMLSelectElement, Event>) => {
      setJobTypeId(+e.currentTarget.value ?? undefined);
      pagination.setCurrentPage(1);
    },
    [setJobTypeId, pagination]
  );
  const handleChangeMode = useCallback(
    (m: Modes) => () => {
      if (m === Modes.Map) {
        pagination.setCurrentPage(1);
        pagination.onChangeItemsPerPage(100000);
      } else {
        pagination.onChangeItemsPerPage(DEFAULT_ITEMS_PER_PAGE);
      }

      setMode(m);
    },
    [setMode, pagination]
  );
  const handleChangeDistance = useCallback(
    (e: SyntheticEvent<HTMLSelectElement, Event>) => {
      setDistance(+e.currentTarget.value);
      pagination.setCurrentPage(1);
    },
    [setDistance, pagination]
  );
  const handleClickJob = useCallback(
    (jobId: number) => () => {
      const job = jobsData?.jobs.find((j) => j.jobId === jobId);
      if ((job?.totalAmountPaid ?? 0) > 0) {
        setCurrentJob(jobId);
        assignJob.onOpen();
      } else {
        setMakeOffer({
          jobId,
          details: "",
          jobQuoteStatusId: 2,
          jobTypeName: job?.jobTypeName,
        });
        makeOfferModal.onOpen();
      }
    },
    [jobsData?.jobs, assignJob, makeOfferModal]
  );

  const handleChangeZip = useCallback(
    debounce((e: ChangeEvent<HTMLInputElement>) => {
      setZip(e.target.value);
    }, 300),
    []
  );

  const mapCenterCoords = useMemo(
    () => ({
      lat: userData?.company?.lat,
      lng: userData?.company?.lng,
    }),
    [userData]
  );

  return (
    <>
      <AvailableJobsPresentation
        breadcrumbsRef={breadcrumbsRef}
        distance={distance}
        isLoading={isLoadingJobs || isLoadingStatics || isLoadingProfile}
        isLoadingProfile={isLoadingProfile}
        jobTypeId={jobTypeId}
        jobsData={jobsData}
        mapCenterCoords={mapCenterCoords}
        mode={mode}
        pageTitleRef={pageTitleRef}
        pagination={pagination}
        statics={staticsData}
        zip={zip}
        onChangeDistance={handleChangeDistance}
        onChangeJobType={handleChangeJobType}
        onChangeMode={handleChangeMode}
        onChangeZip={handleChangeZip}
        onClickJob={handleClickJob}
      />
      <AssignJob jobId={currentJob} />
      <MakeOfferModal makeOfferData={makeOffer} />
    </>
  );
};
