import React from "react";
import {
  Text,
  Flex,
  Box,
  Link,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  Icon,
  // Center,
} from "@chakra-ui/react";
import { translations } from "../../i18n/translations";
import { t } from "i18next";
import { Logo } from "../../components/LogoBlack/LogoBlack";
import { Trans } from "react-i18next";
import { AiFillApple as AppleIcon } from "react-icons/ai";
import { IoIosCheckmarkCircle as CheckIcon } from "react-icons/io";
import { NavLink as RouterLink } from "react-router-dom";
import { PresentationProps } from "./Signup.types";
import { Routing } from "../../constants/routing";
// import { FcGoogle } from "react-icons/fc";

export const SignupPresentation = (props: PresentationProps) => {
  const { register, onSubmit, errors, isSubmitting, onAppleSignIn } = props;
  const screen = translations.screens.signup;

  return (
    <Flex display="flex" flexDirection="column" height="100%">
      <Flex alignItems="center" gap="2" justifyContent="space-between" p={4}>
        <Box>
          <Logo width={{ base: "79px", sm: "79px", md: "79px", lg: "112px" }} />
        </Box>
        <Flex direction="row" gap={1}>
          <Text fontSize={{ base: 12, sm: 14 }}>Welcome, </Text>
          <Link
            _focus={{ boxShadow: "none" }}
            as={RouterLink}
            to={Routing.Login}
          >
            <Text
              color="#FB7C23"
              fontSize={{ base: 12, sm: 14 }}
              fontWeight={600}
            >
              Log in
            </Text>
          </Link>
        </Flex>
      </Flex>

      <Flex
        alignItems="center"
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        px={4}
      >
        <Flex alignItems="center" flexDirection="column">
          <Box
            backgroundColor="#FFFAF7"
            borderRadius={999}
            height="24px"
            padding="2px 8px"
            textAlign="center"
            whiteSpace="nowrap"
            width="auto"
          >
            <Text color="#FB7C23" fontSize="12px" fontWeight={700}>
              {t<string>(screen.title)}
            </Text>
          </Box>
          <Text
            color="#101828"
            fontSize={{ base: "24px", lg: "36px" }}
            fontWeight={700}
            mt={2}
            textAlign="center"
          >
            {t<string>(screen.heading)}
          </Text>
        </Flex>

        <Flex maxWidth="400px" mt="32px" width="100%">
          <form
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            onSubmit={onSubmit}
          >
            <FormControl isInvalid={Boolean(errors.firstName?.message)}>
              <FormLabel
                color="#344054"
                fontSize={14}
                fontWeight={500}
                htmlFor="firstName"
                mt="20px"
              >
                {t<string>(screen.form.name)}
              </FormLabel>
              <Input
                autoComplete="off"
                height="44px"
                id="firstName"
                {...register("firstName", { required: "This is required" })}
                placeholder="Enter your first name"
                width="100%"
              />
              <FormErrorMessage>
                {errors.firstName && errors.firstName.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={Boolean(errors.email?.message)}>
              <FormLabel
                color="#344054"
                fontSize={14}
                fontWeight={500}
                htmlFor="email"
                mt="20px"
              >
                {t<string>(screen.form.email)}
              </FormLabel>
              <Input
                autoComplete="off"
                height="44px"
                id="email"
                {...register("email", {
                  required: "This is required",
                  minLength: {
                    value: 4,
                    message: "Minimum length should be 4",
                  },
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                    message: "Please enter a valid email address",
                  },
                })}
                placeholder="Enter your work email"
                width="100%"
              />
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={Boolean(errors.password?.message)}>
              <FormLabel
                color="#344054"
                fontSize={14}
                fontWeight={500}
                htmlFor="password"
                mt="20px"
              >
                {t<string>(screen.form.password)}
              </FormLabel>
              <Input
                autoComplete="off"
                height="44px"
                id="password"
                type="password"
                {...register("password", {
                  required: "This is required",
                  minLength: {
                    value: 8,
                    message: "Must be at least 8 characters",
                  },
                  pattern: {
                    value: /^(?=.*[!@#$%^&*])/,
                    message: "Must contain one special character",
                  },
                })}
                placeholder="Create a password"
                width="100%"
              />
              <FormErrorMessage>
                {errors.password && errors.password.message}
              </FormErrorMessage>
            </FormControl>

            <Flex alignItems="flex-start" gap="1" mt="20px" width="100%">
              <Icon as={CheckIcon} color="#D0D5DD" fontSize="2xl" />
              <Text color="#475467" fontSize="16px" fontWeight={400}>
                Must be at least 8 characters
              </Text>
            </Flex>

            <Flex alignItems="flex-start" gap="1" mt="12px" width="100%">
              <Icon as={CheckIcon} color="#D0D5DD" fontSize="2xl" />
              <Text color="#475467" fontSize="16px" fontWeight={400}>
                Must contain one special character
              </Text>
            </Flex>

            <Button
              _hover={{ bg: "orange.600" }}
              backgroundColor="#FB7C23"
              color="white"
              isLoading={isSubmitting}
              mt="24px"
              type="submit"
              width="100%"
            >
              {t<string>(screen.form.signup)}
            </Button>

            <Flex
              alignItems="center"
              flexDirection="column"
              gap={2}
              mt="16px"
              width="100%"
            >
              <Button
                fontFamily='"Google Sans",arial,sans-serif'
                fontSize="15px"
                fontWeight={500}
                gap={1}
                mb="6px"
                size="md"
                variant="outline"
                width="100%"
                onClick={onAppleSignIn}
              >
                <Box ml="-16px">
                  <AppleIcon fontSize="26px" />
                </Box>
                <Flex>{t<string>(screen.form.signInWithApple)}</Flex>
              </Button>
              <div id="google-button" style={{ maxWidth: "400px" }} />
              {/* <Button
                leftIcon={<FcGoogle />}
                maxWidth="400px"
                variant="outline" // Ensures full width
                width="100%" // Optional, to set a maximum width if needed
                onClick={() => {
                  // const googleBtn = document.getElementById("button-label");
                  // if (googleBtn) googleBtn.click();
                  if (
                    typeof google !== "undefined" &&
                    google.accounts &&
                    google.accounts.id
                  ) {
                    google.accounts.id.prompt(); // Directly show the Google sign-in prompt
                  } else {
                    console.error("Google accounts library is not loaded.");
                  }
                }}
              >
                <Center>
                  <Text>Sign up with Google</Text>
                </Center>
              </Button> */}
            </Flex>

            <Flex alignItems="center" gap={1} mt="32px">
              <Text color="gray.600" fontSize={14} fontWeight={400}>
                Already have an account?
              </Text>
              <Link
                _focus={{ boxShadow: "none" }}
                as={RouterLink}
                to={Routing.Login}
              >
                <Text color="#FB7C23" fontSize={14} fontWeight={600}>
                  Log in
                </Text>
              </Link>
            </Flex>

            <Flex
              alignSelf="center"
              display="inline-block"
              fontSize="12px"
              justifyContent="center"
              lineHeight="18px"
              mt={6}
              // px={6}
              textAlign="center"
              width="100%"
            >
              <Trans i18nKey="screens.login.acceptTerms">
                By continuing you agree to the
                <Link
                  color="#FB7C23"
                  href="https://www.nationalonsite.com/legal/privacy"
                  target="_blank"
                >
                  privacy policy
                </Link>
                <Link
                  color="#FB7C23"
                  href="https://www.nationalonsite.com/legal/terms-of-use"
                  target="_blank"
                >
                  terms of use
                </Link>
                <Link
                  color="#FB7C23"
                  href="https://www.nationalonsite.com/legal/terms-of-service"
                  target="_blank"
                >
                  terms of service
                </Link>
              </Trans>
            </Flex>
          </form>
        </Flex>
      </Flex>
    </Flex>
  );
};
