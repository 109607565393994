import React from "react";
import { Divider, Flex, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { translations } from "../../../../i18n/translations";
import { Props } from "./PlanDetails.types";
import { Plan } from "../Plan/Plan.container";

export const PlanDetailsTab = (props: Props) => {
  const { data } = props;
  const screen = translations.screens.profile;
  return (
    <>
      <Flex justifyContent="space-between" mt="12px">
        <Flex flexDirection="column">
          <Text fontSize="18px" fontWeight={600}>
            {t<string>(screen.plan)}
          </Text>
          <Text fontSize="14px" fontWeight={400}>
            {t<string>(screen.planSubheading)}
          </Text>
        </Flex>
      </Flex>
      <br />
      <Divider />
      <Plan data={data} />
      <Divider />
    </>
  );
};
