import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";
import { AssignJobSteps } from "./AssignJob.types";
import { translations } from "../../i18n/translations";
import { JobInfo } from "./components/JobInfo";
import { TStatics } from "../../api/modules/Statics/types";
import { TJob } from "../../api/modules/Jobs/types";
import { TTeam, TTeamMember } from "../../api/modules/Team/types";
import { UserSelection } from "./components/UserSelection";
import { ResultScreen } from "./components/ResultScreen";

type Props = {
  currentTeamMemberData?: TTeamMember;
  currentUser?: number;
  isOpen: boolean;
  isReassignMode?: boolean;
  isLoadingJob: boolean;
  onClose: () => void;
  onAccept: () => void;
  onSetCurrentUser: (userId: number) => () => void;
  onAssignJob: () => void;
  step: AssignJobSteps;
  statics?: TStatics;
  team?: TTeam;
  job?: TJob;
};

export const AssignJobPresentation = (props: Props) => {
  const {
    currentUser,
    currentTeamMemberData,
    isOpen,
    statics,
    job,
    step,
    isReassignMode,
    isLoadingJob,
    team,
    onClose,
    onAccept,
    onSetCurrentUser,
    onAssignJob,
  } = props;
  const screen = translations.screens.assignJob;

  return (
    <>
      <Modal isOpen={isOpen} size="xl" onClose={onClose}>
        <ModalOverlay />
        <ModalContent width="1200px">
          <ModalHeader mt={7} px={7}>
            <Flex alignItems="center" justifyContent="space-between">
              {step === AssignJobSteps.Details && (
                <Flex flexDirection="column">
                  <Text fontSize="lg">{t<string>(screen.details.title)}</Text>
                  {!isLoadingJob && (
                    <Text
                      color="green.400"
                      fontSize="lg"
                      fontWeight={500}
                      mt={2}
                    >
                      ${job?.totalAmountPaid}
                    </Text>
                  )}
                </Flex>
              )}
              {step === AssignJobSteps.UserSelection && !isLoadingJob && (
                <Text fontSize="xl">
                  {t<string>(
                    screen.userSelection[
                      isReassignMode ? "reassignTitle" : "title"
                    ]
                  )}
                </Text>
              )}
              {step === AssignJobSteps.Details && !isLoadingJob && (
                <Button colorScheme="nosPurple" size="sm" onClick={onAccept}>
                  {t<string>(screen.details.acceptJob)}
                </Button>
              )}
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={5} px={7}>
            {isLoadingJob ? (
              <Flex justifyContent="center" my={4}>
                <Spinner />
              </Flex>
            ) : (
              <>
                {step === AssignJobSteps.Details && (
                  <JobInfo job={job} statics={statics} />
                )}
                {step === AssignJobSteps.UserSelection && (
                  <UserSelection
                    currentUser={currentUser}
                    isReassignMode={isReassignMode}
                    team={team}
                    onAssign={onAssignJob}
                    onSetCurrentUser={onSetCurrentUser}
                  />
                )}
                {step === AssignJobSteps.Result && (
                  <ResultScreen person={currentTeamMemberData} />
                )}
              </>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
