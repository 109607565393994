import React, { useState, useEffect } from "react";
import {
  Checkbox,
  Flex,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
  Text,
} from "@chakra-ui/react";
import { Props } from "./TradeLicenses.types";
import { useStatics } from "../../../../api/modules/Statics/useStatics";

type LicenseState = {
  [key: number]: {
    isChecked: boolean;
    licenseNumber: string;
    previousLicenseNumber: string;
  };
};

export const TradeLicenses = (props: Props) => {
  const { isUpdatingProfile, onEditField, formData } = props;
  const { data: staticsData } = useStatics();

  const [licenseStates, setLicenseStates] = useState<LicenseState>({});

  useEffect(() => {
    if (staticsData?.licenseTypes) {
      const initialLicenseStates: LicenseState = {};
      const initialUserLicenses = staticsData.licenseTypes.map(
        (licenseType) => {
          const matchedLicense = formData?.userLicenses?.find(
            (userLicense) => userLicense.licenseTypeId === licenseType.id
          );

          initialLicenseStates[licenseType.id] = {
            isChecked: matchedLicense?.isActive || false,
            licenseNumber: matchedLicense?.licenseNumber || "",
            previousLicenseNumber: matchedLicense?.licenseNumber || "",
          };

          return {
            licenseTypeId: licenseType.id,
            isActive: matchedLicense?.isActive || false,
            licenseNumber: matchedLicense?.licenseNumber || "",
          };
        }
      );

      setLicenseStates(initialLicenseStates);

      // Initialize `userLicenses` in `formData` only if it's null or undefined
      if (!formData.userLicenses || formData.userLicenses.length === 0) {
        onEditField("userLicenses")({
          target: { value: initialUserLicenses },
        } as unknown as React.ChangeEvent<HTMLInputElement>);
      }
    }
  }, [staticsData, formData, onEditField]);

  const handleCheckboxChange =
    (licenseTypeId: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = e.target.checked;

      setLicenseStates((prev) => ({
        ...prev,
        [licenseTypeId]: {
          ...prev[licenseTypeId],
          isChecked,
          licenseNumber: isChecked
            ? prev[licenseTypeId].previousLicenseNumber
            : "",
          previousLicenseNumber: !isChecked
            ? prev[licenseTypeId].licenseNumber
            : prev[licenseTypeId].previousLicenseNumber,
        },
      }));

      const updatedUserLicenses = (formData.userLicenses || []).map((license) =>
        license.licenseTypeId === licenseTypeId
          ? {
              ...license,
              isActive: isChecked,
              licenseNumber: isChecked ? license.licenseNumber : "", // Clear license number if unchecked
            }
          : license
      );

      // Make sure to update formData with the modified userLicenses
      onEditField("userLicenses")({
        target: { value: updatedUserLicenses },
      } as unknown as React.ChangeEvent<HTMLInputElement>);
    };

  const handleInputChange =
    (licenseTypeId: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      setLicenseStates((prev) => ({
        ...prev,
        [licenseTypeId]: {
          ...prev[licenseTypeId],
          licenseNumber: value,
        },
      }));

      // Safely update the specific license number in userLicenses array
      const updatedUserLicenses = formData.userLicenses
        ? formData.userLicenses.map((license) =>
            license.licenseTypeId === licenseTypeId
              ? { ...license, licenseNumber: value }
              : license
          )
        : [];

      onEditField("userLicenses")({
        target: { value: updatedUserLicenses },
      } as unknown as React.ChangeEvent<HTMLInputElement>);
    };

  return (
    <TableContainer maxWidth="100%" width="100%">
      <Table variant="">
        <Tbody fontSize="sm">
          <Tr>
            <Td>
              <Flex flexDirection="column" gap="16px">
                {staticsData?.licenseTypes?.map((licenseType) =>
                  licenseType.active ? (
                    <Flex key={licenseType.id} flexDirection="column" gap={2}>
                      <Checkbox
                        isChecked={
                          licenseStates[licenseType.id]?.isChecked || false
                        }
                        isDisabled={isUpdatingProfile}
                        onChange={handleCheckboxChange(licenseType.id)}
                      >
                        <Text fontSize="14px">{licenseType.name}</Text>
                      </Checkbox>
                      {licenseStates[licenseType.id]?.isChecked && (
                        <Input
                          disabled={
                            isUpdatingProfile ||
                            !licenseStates[licenseType.id]?.isChecked
                          }
                          placeholder={`${licenseType.name} license no.`}
                          value={
                            licenseStates[licenseType.id]?.licenseNumber || ""
                          }
                          width="229px"
                          onChange={handleInputChange(licenseType.id)}
                        />
                      )}
                    </Flex>
                  ) : null
                )}
              </Flex>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};
