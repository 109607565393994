import { useQuery } from "react-query";
import { Queries } from "../../../constants/queries";
import { getJobQuote } from "./getJobQuote";
import { t } from "i18next";
import { translations } from "../../../i18n/translations";
import { useToast } from "@chakra-ui/react";
import { TJobQuote } from "./types";

export const useJobQuote = (jobId?: number) => {
  const toast = useToast();
  return useQuery<TJobQuote>(Queries.JobQuote(jobId), getJobQuote(jobId!), {
    staleTime: Infinity,
    cacheTime: Infinity,
    onError: () => {
      toast({
        title: "Error",
        description: t<string>(translations.errors.job_error),
        status: "error",
        position: "top-right",
      });
    },
    enabled: Boolean(jobId),
  });
};
