import React, { useCallback, useState } from "react";
import { Box, Button, Flex, Text, useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { translations } from "../../../../i18n/translations";
import { Props } from "./CompanyDetails.types";
import { CompanyData } from "../CompanyData";
import { useNavigate } from "react-router-dom";
import { Routing } from "../../../../constants/routing";

export const CompanyDetailsTab = (props: Props) => {
  const {
    companyLogoUrl,
    data,
    formData,
    onEditField,
    inAccountWizard,
    isEditMode,
    isStripeConnected,
    isUpdatingCompany,
    profileFormData,
    onCompanyBack,
    onSaveCompany,
    onTabChange,
  } = props;
  const screen = translations.screens.profile;
  const navigate = useNavigate();
  const toast = useToast();

  const [isNextClicked, setIsNextClicked] = useState(false);
  const handleClickCTA = useCallback(async () => {
    if (onTabChange) {
      onTabChange(2); // Ensure onTabChange is defined before invoking it
    }
    setIsNextClicked(true);
    await onSaveCompany();
    setIsNextClicked(false);
  }, [onSaveCompany, onTabChange]);

  const handleFinish = useCallback(async () => {
    const id = "completed";
    if (!toast.isActive(id)) {
      toast({
        id,
        title: "",
        description: "Account Completed",
        position: "top-right",
        status: "success",
        isClosable: true,
        duration: 10000,
        variant: "subtle",
        containerStyle: { color: "#067647" },
      });
    }
    navigate(Routing.Team);
  }, [navigate, toast]);

  return (
    <>
      <Flex flexDirection="column" mt="12px">
        <Text fontSize="18px" fontWeight={600}>
          {t<string>(screen.companyInformation)}
        </Text>
      </Flex>
      <CompanyData
        companyLogoUrl={companyLogoUrl}
        data={data}
        formData={formData}
        isEditMode={isEditMode || inAccountWizard}
        isUpdatingCompany={isUpdatingCompany}
        profileFormData={profileFormData}
        onEditField={onEditField}
      />
      <br />
      <Box display="flex" justifyContent={"flex-end"}>
        <Flex>
          {isEditMode && !inAccountWizard && (
            <>
              <Box ml={3}>
                <Button
                  colorScheme="nosPurple"
                  isLoading={isUpdatingCompany}
                  onClick={onSaveCompany}
                >
                  {t<string>(screen.save)}
                </Button>
              </Box>
            </>
          )}
          {inAccountWizard && (
            <Flex gap={2}>
              <Button
                colorScheme="nosPurple"
                size="sm"
                variant="outline"
                onClick={onCompanyBack}
              >
                {t<string>(screen.back)}
              </Button>
              <Button
                colorScheme="nosPurple"
                isLoading={isUpdatingCompany && isNextClicked}
                size="sm"
                onClick={isStripeConnected ? handleFinish : handleClickCTA}
              >
                {t<string>(screen.next)}
              </Button>
            </Flex>
          )}
        </Flex>
      </Box>
    </>
  );
};
