import { ComponentStyleConfig } from "@chakra-ui/react";
import { nosPurple } from "../colors/nosPurple";

export const Tabs: ComponentStyleConfig = {
  baseStyle: {
    tablist: {
      borderBottomWidth: "0!important",
      color: "gray.500",
      // fontSize: "14px",
      // fontWeight: 600,
    },
    tab: {
      "font-size": "14px !important",
      fontWeight: 600,
      color: "gray.500",
      _selected: {
        // fontSize: "14px !important",
        // fontWeight: 600,
        color: nosPurple[700],
      },
      // _hover: {
      //   color: "gray.600", // Optional: Change hover color for tabs
      // },
    },
  },
};
