import { Button, Flex, Input, Text, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { t } from "i18next";
import { translations } from "../../../../../../i18n/translations";
import {
  Month_Names_Short,
  OnDateSelected,
  RangeCalendarPanel,
} from "chakra-dayzed-datepicker";
import { endOfDay, format, subMonths, subYears } from "date-fns";
import {
  ISO_DATE_FORMAT,
  MANIFEST_DATE_FORMAT,
} from "../../../../../../constants/global";

type Props = {
  isLoading: boolean;
  onCreateManifest: (startDate: string, endDate: string) => void;
  onClose: () => void;
};

export const Details = (props: Props) => {
  const { isLoading, onCreateManifest, onClose } = props;
  const screen = translations.screens.pumperPanel.manifests.createManifestModal;

  const today = new Date();
  const [selectedDates, setSelectedDates] = useState<Date[]>([today, today]);

  const handleOnDateSelected: OnDateSelected = ({ date }) => {
    const newDates = [...selectedDates];
    if (selectedDates.length === 1) {
      const firstTime = selectedDates[0];
      if (firstTime < date) {
        newDates.push(date);
      } else {
        newDates.unshift(date);
      }
      setSelectedDates(newDates);
    } else {
      setSelectedDates([date]);
    }
  };

  const handleSmartButtonClick = (type: string) => {
    let startDate: Date, endDate: Date;
    const now = new Date();

    switch (type) {
      case "today":
        startDate = now;
        endDate = now;
        break;
      case "thisWeek": {
        const currentDayIndex = now.getDay();
        const daysToMonday = (currentDayIndex === 0 ? -6 : 1) - currentDayIndex;
        startDate = new Date(now);
        startDate.setDate(now.getDate() + daysToMonday);
        startDate.setHours(0, 0, 0, 0);
        endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 6);
        endDate.setHours(23, 59, 59, 999);
        break;
      }
      case "thisMonth":
        startDate = new Date(now.getFullYear(), now.getMonth(), 1);
        endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case "3Months":
        startDate = subMonths(now, 3);
        endDate = now;
        break;
      case "6Months":
        startDate = subMonths(now, 6);
        endDate = now;
        break;
      case "thisYear":
        startDate = new Date(now.getFullYear(), 0, 1);
        endDate = now;
        break;
      case "3Years":
        startDate = subYears(now, 3);
        endDate = now;
        break;
      case "5Years":
        startDate = subYears(now, 5);
        endDate = now;
        break;
      default:
        startDate = today;
        endDate = today;
    }
    setSelectedDates([startDate, endDate]);
  };

  const handleCreateManifestClick = () => {
    const startDate = format(selectedDates[0], ISO_DATE_FORMAT);
    let endDate = format(selectedDates[1], ISO_DATE_FORMAT);
    // If startDate and endDate are the same, set endDate to the end of the day
    if (startDate === endDate) {
      endDate = format(endOfDay(selectedDates[1]), ISO_DATE_FORMAT);
    }
    onCreateManifest(startDate, endDate);
  };

  return (
    <Flex flexDirection="column">
      <Flex flexDirection="row">
        <Flex
          borderRight="1px"
          borderRightColor="#EAECF0"
          direction="column"
          mt="-8px"
          width="20%"
        >
          <VStack
            alignItems={{ base: "flex-end", md: "flex-start" }}
            // marginRight={{ base: "4px", md: "0" }}
            mb={10}
            mt="12px"
            spacing={5}
          >
            <Button
              color="#344054"
              fontSize="14px"
              fontWeight={500}
              variant="link"
              onClick={() => handleSmartButtonClick("today")}
            >
              Today
            </Button>
            <Button
              color="#344054"
              fontSize="14px"
              fontWeight={500}
              variant="link"
              onClick={() => handleSmartButtonClick("thisWeek")}
            >
              This Week
            </Button>
            <Button
              color="#344054"
              fontSize="14px"
              fontWeight={500}
              variant="link"
              onClick={() => handleSmartButtonClick("thisMonth")}
            >
              This Month
            </Button>
            <Button
              color="#344054"
              fontSize="14px"
              fontWeight={500}
              variant="link"
              onClick={() => handleSmartButtonClick("3Months")}
            >
              3 Months
            </Button>
            <Button
              color="#344054"
              fontSize="14px"
              fontWeight={500}
              variant="link"
              onClick={() => handleSmartButtonClick("6Months")}
            >
              6 Months
            </Button>
            <Button
              color="#344054"
              fontSize="14px"
              fontWeight={500}
              variant="link"
              onClick={() => handleSmartButtonClick("thisYear")}
            >
              This Year
            </Button>
            <Button
              color="#344054"
              fontSize="14px"
              fontWeight={500}
              variant="link"
              onClick={() => handleSmartButtonClick("3Years")}
            >
              3 Years
            </Button>
            <Button
              color="#344054"
              fontSize="14px"
              fontWeight={500}
              variant="link"
              onClick={() => handleSmartButtonClick("5Years")}
            >
              5 Years
            </Button>
          </VStack>
        </Flex>

        <Flex direction="column">
          <Flex flex="1">
            <RangeCalendarPanel
              configs={{
                dateFormat: "MM/dd/yyyy",
                monthNames: Month_Names_Short,
                dayNames: "Mo,Tu,We,Th,Fr,Sat,Su".split(","), //Weekday_Names_Short,
                firstDayOfWeek: 6,
              }}
              dayzedHookProps={{
                showOutsideDays: false,
                onDateSelected: handleOnDateSelected,
                selected: selectedDates,
                monthsToDisplay: 2,
              }}
              propsConfigs={{
                dateNavBtnProps: {
                  // display: "none",
                },
                dayOfMonthBtnProps: {
                  defaultBtnProps: {
                    borderRadius: "full",
                    _hover: {
                      background: "#FB7C23",
                      color: "white",
                      borderRadius: "full",
                    },
                  },
                  isInRangeBtnProps: {
                    backgroundColor: "#FB7C23",
                    color: "white",
                    borderRadius: "full",
                  },
                  selectedBtnProps: {
                    background: "#FB7C23",
                    color: "white",
                    borderRadius: "full",
                  },
                },
                calendarPanelProps: {
                  wrapperProps: {
                    marginTop: "-8px",
                    borderWidth: "1px",
                    borderColor: "#EAECF0",
                    borderStyle: "solid",
                    borderTop: "0px",
                    borderLeft: "none",
                  },
                  bodyProps: {
                    border: "none",
                    borderWidth: "0px",
                  },
                  dividerProps: {
                    border: "none",
                    borderWidth: "0px",
                  },
                  contentProps: {
                    border: "none",
                  },
                },
                dateHeadingProps: {
                  fontSize: "16px",
                  fontWeight: 600,
                  color: "#344054",
                },
              }}
              selected={selectedDates}
            />
          </Flex>

          <Flex flex="1" flexDirection={["column", "column", "row"]} mt={4}>
            <Flex alignItems="left" gap={4} marginLeft="16px">
              <Input
                fontSize="16px"
                fontWeight={400}
                value={format(selectedDates[0], MANIFEST_DATE_FORMAT)}
                width="136px"
              />
              <Text fontWeight={600} mt={2}>
                -
              </Text>
              <Input
                fontSize="16px"
                fontWeight={400}
                readOnly={true}
                value={
                  selectedDates.length > 1
                    ? format(selectedDates[1], MANIFEST_DATE_FORMAT)
                    : format(new Date(), MANIFEST_DATE_FORMAT)
                }
                width="136px"
              />
            </Flex>
            <Flex
              flex="1"
              gap={2}
              justifyContent={["flex-start", "flex-start", "flex-end"]}
              mr="16px"
              mt={["12px", "12px", "0px"]}
              // paddingLeft={{ base: "16px", md: "16px", xs: "16px" }}
            >
              <Button
                fontSize="14px"
                fontWeight={600}
                variant="outline"
                onClick={onClose}
              >
                {t<string>(screen.details.cancel)}
              </Button>
              <Button
                _hover={{ bg: "orange.600" }}
                backgroundColor="#FB7C23"
                color="white"
                fontSize="14px"
                fontWeight={600}
                isLoading={isLoading}
                size="md"
                onClick={handleCreateManifestClick}
              >
                {t<string>(screen.details.createLog)}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
