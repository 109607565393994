import { useMutation } from "react-query";
import { useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { postQuoteJob } from "./postQuoteJob";
import { TData, TPostMakeOfferBody } from "./types";
import { translations } from "../../../i18n/translations";

export const useQuoteJob = () => {
  const toast = useToast();
  return useMutation<TData, unknown, TPostMakeOfferBody>(
    (payload) => postQuoteJob(payload),
    {
      onError: () => {
        toast({
          title: "Error",
          description: t<string>(translations.errors.invite_team_member_error),
          status: "error",
          position: "top-right",
        });
      },
    }
  );
};
