import React, { useContext, useState } from "react";
import { TruckPresentation } from "./Truck.presentation";
import { PortalsContext } from "../../../contexts/PortalsContext";
import { ModalsContext } from "../../../contexts/ModalsContext/ModalsContext";
import { useUser } from "../../../api/modules/User";
import { useCompanyTrucks } from "../../../api/modules/GetCompanyTrucks";
import { CreateTruckModal } from "./CreateTruckModal";
import { TruckFormType } from "./CreateTruckModal/CreateTruckModal.types";

export const Truck = () => {
  const { pageTitleRef, breadcrumbsRef } = useContext(PortalsContext);
  const { data: userData } = useUser();
  const { createTruckModal } = useContext(ModalsContext);
  const [companyTruck, setCompanyTruck] = useState<Partial<TruckFormType>>();

  const truckQuery = useCompanyTrucks({
    companyId: userData?.companyId,
  });
  const { data: truckData, isLoading: isLoading } = truckQuery || {};

  const onEditClick = (id: number) => {
    const truck = truckData?.find((f) => f.truckId === id);
    const existingTruckData: Partial<TruckFormType> = {
      nickName: truck?.assetName,
      licensePlate: truck?.licensePlate,
      make: truck?.make,
      model: truck?.model,
      year: truck?.year,
      pumpingLicense: truck?.pumpingLicenceNumber,
      truckId: truck?.truckId,
    };
    setCompanyTruck(existingTruckData);
    createTruckModal.onOpen();
  };

  const onInviteModalOpen = () => {
    setCompanyTruck(undefined);
    createTruckModal.onOpen();
  };

  return (
    <>
      <TruckPresentation
        breadcrumbsRef={breadcrumbsRef}
        companyTruck={truckData}
        isLoading={Boolean(isLoading)}
        pageTitleRef={pageTitleRef}
        onEditClick={onEditClick}
        onInviteModalOpen={onInviteModalOpen}
      />
      <CreateTruckModal companyTruck={companyTruck} />
    </>
  );
};
