import { extendTheme } from "@chakra-ui/react";
import { Table } from "./components/table";
import { Tag } from "./components/tag";
import { Tabs } from "./components/tabs";
// import { ToastStyle } from "./components/toastStyle";
import { blue } from "./colors/blue";
import { orange } from "./colors/orange";
import { CheckboxStyle } from "./components/checkboxStyle";
import { nosPurple } from "./colors/nosPurple";
import { ButtonStyle } from "./components/button";
import { ToastStyle } from "./components/toastStyle";

import "@fontsource/inter/400.css"; // Regular font weight
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css"; // Bold font weight

export const theme = extendTheme({
  components: {
    Table,
    Tag,
    Tabs,
    Checkbox: CheckboxStyle,
    Button: ButtonStyle,
    Toast: ToastStyle,
  },
  colors: {
    nosPurple,
    blue,
    orange,
  },
  fonts: {
    body: "Inter",
    // heading: "Inter",
  },
});
