import React from "react";
import Layout from "../../../../components/Layout";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Divider,
  Flex,
  Portal,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";

import { t } from "i18next";
import { Routing } from "../../../../constants/routing";
import { translations } from "../../../../i18n/translations";
import { PresentationProps } from "./Profile.types";
import { Link as RouterLink } from "react-router-dom";

import { UpdateRoles } from "../../UpdateRoles";
import { RemoveFromTeam } from "../../../RemoveFromTeamModal";
import { PersonalDetails } from "../PersonalDetails";
import { CompanyDetails } from "../CompanyDetails";
import FullPageContent from "../../../../components/FullPageContent";

import { StartupModal } from "../../../StartupModal";
import { PaymentDetails } from "../PaymentDetails";
import { PlanDetails } from "../PlanDetails";

export const ProfilePresentation = (props: PresentationProps) => {
  const screen = translations.screens.profile;
  const {
    companyData,
    isEditable,
    pageTitleRef,
    breadcrumbsRef,
    isLoading,
    isUpdatingProfile,
    isUpdatingProfileNext,
    data,
    isEditMode,
    onSetEditMode,
    onCancelEditMode,
    onEditField,
    onSaveProfile,
    formData,
    companyFormData,
    isCompanyEditable,
    isEditCompanyMode,
    isUpdatingCompany,
    inAccountWizard,
    isStripeConnected,
    tabIndex,
    onTabChange,
    onCompanyBack,
    onSetEditCompanyMode,
    onPersonalNext,
    onCancelCompanyEditMode,
    onEditCompanyField,
    onEditCompanyCheckboxField,
    onSaveCompany,
    onCancel,
  } = props;
  const { company } = data || {};
  const { companyLogoUrl } = companyData || {};

  return (
    <>
      <Layout>
        <Portal containerRef={pageTitleRef}>
          {t<string>(screen.pageTitle)}
        </Portal>
        <Portal containerRef={breadcrumbsRef}>
          <Breadcrumb fontWeight={500}>
            <BreadcrumbItem color="gray.400">
              <BreadcrumbLink as={RouterLink} to={Routing.Root}>
                {t<string>(screen.breadcrumbs.root)}
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <div>{t<string>(screen.breadcrumbs.profile)}</div>
            </BreadcrumbItem>
          </Breadcrumb>
        </Portal>
        <FullPageContent>
          <Text fontSize="lg" fontWeight={700}>
            {t<string>(screen.pageTitle)}
          </Text>
          <br />
          {isLoading && (
            <Flex justifyContent="center" mt={4}>
              <Spinner />
            </Flex>
          )}
          {!isLoading && (
            <Flex flex={1} flexDirection="column">
              <Divider />
              <br />
              <Tabs
                display="flex"
                flex={1}
                flexDirection="column"
                index={tabIndex}
                onChange={onTabChange}
              >
                <TabList
                  overflowX="auto"
                  sx={{
                    "&::-webkit-scrollbar": { display: "none" }, // Hides scrollbar for aesthetics
                  }}
                  whiteSpace="nowrap"
                >
                  <Tab>{t<string>(screen.personalDetails)}</Tab>
                  <Tab>{t<string>(screen.companyInformation)}</Tab>
                  <Tab>{t<string>(screen.payment)}</Tab>
                  <Tab>{t<string>(screen.plan)}</Tab>
                </TabList>
                <Divider />
                <TabPanels display="flex" flex={1} flexDirection="column">
                  <TabPanel>
                    <PersonalDetails
                      data={data}
                      formData={formData}
                      inAccountWizard={inAccountWizard}
                      isEditMode={isEditMode}
                      isEditable={isEditable}
                      isUpdatingProfile={isUpdatingProfile}
                      isUpdatingProfileNext={isUpdatingProfileNext}
                      onCancel={onCancel}
                      onCancelEditMode={onCancelEditMode}
                      onEditField={onEditField}
                      onPersonalNext={onPersonalNext}
                      onSaveProfile={onSaveProfile}
                      onSetEditMode={onSetEditMode}
                    />
                  </TabPanel>
                  <TabPanel>
                    <CompanyDetails
                      companyLogoUrl={companyLogoUrl as string}
                      data={company}
                      formData={companyFormData}
                      inAccountWizard={inAccountWizard}
                      isEditMode={isEditCompanyMode}
                      isEditable={isCompanyEditable}
                      isStripeConnected={isStripeConnected}
                      isUpdatingCompany={isUpdatingCompany}
                      profileFormData={formData}
                      onCancelEditMode={onCancelCompanyEditMode}
                      onCompanyBack={onCompanyBack}
                      onEditCheckboxField={onEditCompanyCheckboxField}
                      onEditField={onEditCompanyField}
                      onSaveCompany={onSaveCompany}
                      onSetEditMode={onSetEditCompanyMode}
                      onTabChange={onTabChange}
                    />
                  </TabPanel>
                  <TabPanel>
                    <PaymentDetails
                      inAccountWizard={inAccountWizard}
                      isStripeConnected={isStripeConnected}
                      onTabChange={onTabChange}
                    />
                  </TabPanel>
                  <TabPanel>
                    <PlanDetails data={data} onTabChange={onTabChange} />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Flex>
          )}
        </FullPageContent>
      </Layout>
      <UpdateRoles userId={data?.userId} />
      <RemoveFromTeam companyId={data?.companyId} userId={data?.userId} />
      <StartupModal />
    </>
  );
};
