import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
} from "@chakra-ui/react";
import { t } from "i18next";
import React, { useEffect } from "react";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { MakeOfferFormType } from "../MakeOfferModal.types";
import { useForm, UseFormRegister } from "react-hook-form";
import { translations } from "../../../../../../i18n/translations";

type Props = {
  isLoading: boolean;
  errors: FieldErrors<MakeOfferFormType>;
  register: UseFormRegister<MakeOfferFormType>;
  onSubmit: (data: MakeOfferFormType) => void;
  onClose: () => void;
  initialValues?: Partial<MakeOfferFormType>;
};

export const MakeOfferForm = (props: Props) => {
  const { isLoading, errors, onSubmit, initialValues, onClose } = props;

  const screen = translations.screens.makeOfferModal.makeOfferForm;

  const { register, handleSubmit, reset } = useForm<MakeOfferFormType>({
    defaultValues: initialValues,
  });

  useEffect(() => {
    reset(initialValues); // Reset without checking condition
  }, [initialValues, reset]);

  const onSubmitForm = (data: MakeOfferFormType) => {
    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Grid gap={4} templateColumns="repeat(2, 1fr)">
        <GridItem colSpan={2}>
          <FormControl isInvalid={Boolean(errors.price?.message)}>
            <FormLabel fontSize="small" htmlFor="nickName">
              {t<string>(screen.price)}
            </FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none">$</InputLeftElement>
              <Input
                id="price"
                {...register("price", {
                  required: "This is required",
                })}
                pl="28px"
                placeholder={t<string>(screen.placeHolder.price)}
              />
            </InputGroup>
            <FormErrorMessage>
              {errors.price && errors.price.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={2}>
          <FormControl isInvalid={Boolean(errors.details?.message)}>
            <FormLabel fontSize="small" htmlFor="details">
              {t<string>(screen.details)}
            </FormLabel>
            <Textarea
              id="details"
              {...register("details", {
                required: "This is required",
              })}
              placeholder={t<string>(screen.placeHolder.details)}
              rows={2}
            />
            <FormErrorMessage>
              {errors.details && errors.details.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>
      {/* <Divider mt={8} px={0} /> */}
      <Flex gap={2} justifyContent="center" mt={8}>
        <Button
          colorScheme="gray"
          size="sm"
          variant="outline"
          width="170px"
          onClick={onClose}
        >
          {t<string>(screen.cancel)}
        </Button>
        <Button
          colorScheme="nosPurple"
          // height="44px"
          isLoading={isLoading}
          size="sm"
          type="submit"
          width="170px"
        >
          {t<string>(
            initialValues?.price === null
              ? screen.sendOffer
              : screen.updateOffer
          )}
        </Button>
      </Flex>
    </form>
  );
};
