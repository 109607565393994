import React, { useRef, useState, useCallback } from "react";
import {
  Avatar,
  Button,
  Checkbox,
  Flex,
  FormControl,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
  Text,
  Tooltip,
  Image,
  VStack,
  Box,
  useToast,
  InputLeftElement,
  InputGroup,
  Icon,
  Divider,
} from "@chakra-ui/react";
import { Props } from "./CompanyData.types";
import { t } from "i18next";
import { translations } from "../../../../i18n/translations";
import { PatternFormat } from "react-number-format";
import { PHONE_PATTERN } from "../../../../constants/global";
import { useUploadProfilePhoto } from "../../../../api/modules/UploadProfilePhoto";
import { getProfileImageUrl } from "../../../../utils/getProfileImageUrl";
import { FiHelpCircle } from "react-icons/fi";
import LogoImg from "../../../../assets/company-logo.png";
import UploadImg from "../../../../assets/upload-icon.png";
import { usePlacesWidget } from "react-google-autocomplete";
import { MdOutlineMailOutline as EmailIcon } from "react-icons/md";
import { BsTelephone } from "react-icons/bs";

export const CompanyData = (props: Props) => {
  const GOOGLE_AUTOCOMPLETE_API_KEY = "AIzaSyD4KmiWyjxiotsznxZ-XsQfqrIBjQ2vN8c";
  const screen = translations.screens.profile.companyDetailsData;
  const {
    data,
    formData,
    onEditField,
    isEditMode,
    isUpdatingCompany,
    companyLogoUrl,
    profileFormData,
  } = props;
  const { companyName, businessPhone, businessEmail, companyId } = data || {};
  const { mutateAsync: handleUploadProfilePhoto } = useUploadProfilePhoto();
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [profile64PicUrl, setProfile64PicUrl] = useState<string>("");
  const toast = useToast();
  const [usePersonalData, setUsePersonalData] = useState(false);
  // Handler for when the checkbox is toggled
  const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setUsePersonalData(isChecked);

    if (isChecked) {
      onEditField("companyName")({
        target: {
          value: `${profileFormData.firstName} ${profileFormData.lastName}`,
        },
      } as React.ChangeEvent<HTMLInputElement>);

      onEditField("businessEmail")({
        target: { value: profileFormData.email },
      } as React.ChangeEvent<HTMLInputElement>);
    } else {
      // Optionally, clear fields when unchecked
      onEditField("companyName")({
        target: { value: companyName },
      } as React.ChangeEvent<HTMLInputElement>);

      onEditField("businessEmail")({
        target: { value: "" },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  const handleFileChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (!file) {
        console.error("No file selected");
        return;
      }
      const allowedTypes = [
        "image/svg+xml",
        "image/png",
        "image/jpeg",
        "image/gif",
      ];

      if (!allowedTypes.includes(file.type)) {
        toast({
          title: "Unsupported file type.",
          description: "Please upload an SVG, PNG, JPG, or GIF file.",
          status: "warning",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        return;
      }

      try {
        const reader = new FileReader();
        reader.onloadend = async () => {
          const base64StringImage = (reader.result as string).split(",")[1];
          setProfile64PicUrl(`data:image/*;base64,${base64StringImage}`);
          const response = await handleUploadProfilePhoto({
            base64StringImage,
            isCompanyLogo: true,
            companyId: companyId,
          });
          if (!response) {
            throw new Error("No response from server");
          }
        };
        reader.readAsDataURL(file);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    },
    [companyId, handleUploadProfilePhoto, toast]
  );

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files?.[0];
    if (!file) {
      console.error("No file was dropped");
      return;
    }
    if (!inputFileRef.current) {
      console.error("Input file ref is not initialized");
      return;
    }
    inputFileRef.current.files = e.dataTransfer.files;
    handleFileChange({
      target: inputFileRef.current,
    } as React.ChangeEvent<HTMLInputElement>);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleUpdateField = (field: string, value: string) => {
    onEditField(field)({
      target: { value },
    } as React.ChangeEvent<HTMLInputElement>);
  };

  // const inputRef = useRef<HTMLInputElement>(null);
  const { ref } = usePlacesWidget({
    apiKey: GOOGLE_AUTOCOMPLETE_API_KEY,
    onPlaceSelected: (place) => {
      let streetAddress = "";
      Object.keys(place?.address_components).forEach((key) => {
        const component = place?.address_components[key];
        const componentType = component.types[0] as string;

        switch (componentType) {
          case "street_number":
            streetAddress = streetAddress.concat(component.long_name);
            break;
          case "route":
            streetAddress = streetAddress.concat(` ${component.long_name}`);
            break;
          case "locality":
            handleUpdateField("city", component.long_name);
            break;
          case "administrative_area_level_1":
            handleUpdateField("state", component.short_name);
            break;
          case "postal_code":
            handleUpdateField("zip", component.long_name);
            break;
          case "country":
            handleUpdateField("country", component.long_name);
            break;
          default:
        }
        handleUpdateField("addressLine1", streetAddress.trim());
      });
    },
    options: {
      types: ["address"],
      componentRestrictions: { country: "us" },
      fields: ["address_components", "name", "formatted_address"],
    },
  });

  return (
    <>
      <Flex mb="16px" mt="16px">
        <Checkbox isChecked={usePersonalData} onChange={onCheckboxChange}>
          <Text fontSize="14px">Same as personal</Text>
        </Checkbox>
      </Flex>
      <Divider />
      <TableContainer maxWidth="100%" width="100%">
        <Table variant="simple">
          <Tbody fontSize="sm">
            <Tr>
              <Td
                fontWeight={600}
                paddingLeft={0}
                verticalAlign="text-top"
                width="280px"
              >
                {t<string>(screen.title)}
              </Td>
              <Td color="gray.500">
                {isEditMode ? (
                  <Input
                    disabled={isUpdatingCompany}
                    value={formData.companyName}
                    width="512px"
                    onChange={onEditField("companyName")}
                  />
                ) : (
                  companyName || "-"
                )}
              </Td>
            </Tr>
            <Tr>
              <Td
                fontWeight={600}
                paddingLeft={0}
                verticalAlign="text-top"
                width="280px"
              >
                {t<string>(screen.email)}
              </Td>
              <Td color="gray.500">
                {isEditMode ? (
                  <InputGroup width="512px">
                    <InputLeftElement
                      pointerEvents="none" // Add the email icon here
                    >
                      <Icon as={EmailIcon} boxSize="20px" color="#667085" />
                    </InputLeftElement>
                    <Input
                      disabled={isUpdatingCompany}
                      value={formData.businessEmail}
                      width="512px"
                      onChange={onEditField("businessEmail")}
                    />
                  </InputGroup>
                ) : (
                  businessEmail || "-"
                )}
              </Td>
            </Tr>
            <Tr>
              <Td
                fontWeight={600}
                paddingLeft={0}
                verticalAlign="text-top"
                width="280px"
              >
                {t<string>(screen.phone)}
              </Td>
              <Td color="gray.500">
                {isEditMode && (
                  <InputGroup width="512px">
                    <InputLeftElement pointerEvents="none">
                      <Icon as={BsTelephone} boxSize="18px" color="#667085" />
                    </InputLeftElement>
                    <PatternFormat
                      customInput={Input}
                      defaultValue={formData.businessPhone ?? ""}
                      disabled={isUpdatingCompany}
                      format={PHONE_PATTERN}
                      pl={9}
                      width="512px"
                      onValueChange={(v) => {
                        onEditField("businessPhone")({
                          target: { value: v.value },
                        } as any);
                      }}
                    />
                  </InputGroup>
                )}
                {!isEditMode && (
                  <PatternFormat
                    displayType="text"
                    format={PHONE_PATTERN}
                    value={businessPhone}
                  />
                )}
              </Td>
            </Tr>
            <Tr>
              <Td verticalAlign="text-top" width="280px">
                <Flex flexDirection="column">
                  <Flex flexDirection="row" gap="2px">
                    <Text fontWeight={600} marginLeft="-24px">
                      {t<string>(screen.companyLogo)}
                    </Text>
                    <Flex alignItems="center">
                      <Tooltip label="Upload a photo">
                        <span>
                          <FiHelpCircle />
                        </span>
                      </Tooltip>
                    </Flex>
                  </Flex>
                  <Text marginLeft="-24px">
                    {t<string>(screen.companyLogoDesc)}
                  </Text>
                </Flex>
              </Td>
              <Td color="gray.500">
                <Flex direction="row" gap="20px">
                  {/* Avatar to display the company logo */}
                  <Flex>
                    <Avatar
                      bg="gray.200"
                      height="64px"
                      icon={<Image src={LogoImg} />}
                      src={getProfileImageUrl({
                        profilePicUrl:
                          companyLogoUrl !== null
                            ? companyLogoUrl
                            : profile64PicUrl,
                      })}
                      width="64px"
                      onClick={() => inputFileRef.current?.click()} // Click triggers file selection
                    />
                  </Flex>

                  <Box
                    _hover={{ borderColor: "#CBD5E0" }}
                    border="1px"
                    borderColor="inherit"
                    borderRadius="md"
                    cursor="pointer"
                    height="126px"
                    p={6}
                    textAlign="center"
                    width="428px"
                    onClick={() => inputFileRef.current?.click()}
                    onDragOver={handleDragOver} // Add this to prevent default behavior
                    onDrop={handleDrop}
                  >
                    <VStack alignContent="start" marginTop="-10px" spacing={1}>
                      <Image src={UploadImg} width="40px" />
                      <Text color="gray.500">
                        <Button
                          colorScheme="nosPurple"
                          variant="link"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevents the Box's onClick from being triggered
                            inputFileRef.current?.click(); // Opens the file input
                          }}
                        >
                          Click to upload
                        </Button>{" "}
                        or drag and drop
                      </Text>
                      <Text color="gray.500">
                        SVG, PNG, JPG or GIF (max. 800x400px)
                      </Text>
                    </VStack>
                  </Box>
                  <Input
                    ref={inputFileRef}
                    accept="image/svg+xml,image/png,image/jpeg,image/gif" //
                    display="none"
                    id="file-input"
                    type="file"
                    onChange={handleFileChange} // Triggered when a file is selected
                  />
                </Flex>
              </Td>
            </Tr>
            <Tr>
              <Td
                fontWeight={600}
                paddingLeft={0}
                verticalAlign="top"
                width="280px"
              >
                {t<string>(screen.address)}
              </Td>
              <Td color="gray.500">
                <Flex flexDirection="column">
                  <FormControl>
                    <Input
                      ref={ref as unknown as React.Ref<HTMLInputElement>}
                      disabled={isUpdatingCompany}
                      placeholder="Street Address"
                      value={formData.addressLine1}
                      width="512px"
                      onChange={onEditField("addressLine1")}
                    />
                  </FormControl>
                  <FormControl mt={2}>
                    <Input
                      disabled={isUpdatingCompany}
                      placeholder="City"
                      value={formData.city}
                      width="512px"
                      onChange={onEditField("city")}
                    />
                  </FormControl>
                  <FormControl mt={2}>
                    <Flex direction="row" gap="24px">
                      <Input
                        disabled={isUpdatingCompany}
                        placeholder="State"
                        value={formData.state}
                        width="155px"
                        onChange={onEditField("state")}
                      />
                      <Input
                        disabled={isUpdatingCompany}
                        placeholder="ZIP Code"
                        value={formData.zip}
                        width="155px"
                        onChange={onEditField("zip")}
                      />
                      <Input
                        disabled={isUpdatingCompany}
                        placeholder="Country"
                        value={formData.country}
                        width="155px"
                        onChange={onEditField("country")}
                      />
                    </Flex>
                  </FormControl>
                </Flex>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};
