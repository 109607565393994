import { useToast } from "@chakra-ui/react";
import { Offerings, Package, PurchasesError } from "@revenuecat/purchases-js";
import { t } from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useDecryptToken } from "../../api/modules/DecryptToken";
import { Queries } from "../../constants/queries";
import { Routing } from "../../constants/routing";
import { translations } from "../../i18n/translations";
import { useLogout } from "../../utils/logout";
import { setAccessToken } from "../../utils/setAccessToken";
import { getUserRevenueCatId } from "../../utils/User/getUserRevenueCatId";
import { setUserRevenueCatId } from "../../utils/User/setUserRevenueCatId";
import { PayWallPresentation } from "./PayWall.presentation";
import { IPaywallProps } from "./PayWall.types";

export const PayWall = ({ purchases }: IPaywallProps) => {
  const [offerings, setOfferings] = useState<Offerings | null>(null);
  const navigate = useNavigate();
  const handleLogout = useLogout();
  const toast = useToast();

  const searchParams = new URLSearchParams(location.search);
  const authToken = searchParams.get("token");
  const { mutateAsync: handleMutateDecryptToken } = useDecryptToken();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (authToken) {
      handleMutateDecryptToken(
        {
          token: authToken,
        },
        {
          onSuccess: (data) => {
            setUserRevenueCatId(data.revenueCatUserId);
            setAccessToken(data.token);

            purchases.changeUser(getUserRevenueCatId() as string);
            purchases.getOfferings().then((offering) => {
              setOfferings(offering);
            });
          },
          onError: (err) => {
            console.log(err);
            toast({
              title: "Error",
              description: t<string>(translations.errors.decrypt_token_error),
              status: "error",
              position: "top-right",
            });
          },
        }
      );
    } else {
      purchases.changeUser(getUserRevenueCatId() as string);
      purchases.getOfferings().then((offering) => {
        setOfferings(offering);
      });
    }
  }, [authToken, handleMutateDecryptToken, purchases, toast]);

  const handleStartPurchase = useCallback(
    async (planPackageId: string) => {
      const pkg = offerings?.all["web"].availablePackages.find(
        (p) => p.identifier === planPackageId
      ) as Package;

      if (!pkg.rcBillingProduct) {
        return;
      }

      try {
        const { customerInfo } = await purchases.purchasePackage(pkg);
        queryClient.invalidateQueries(Queries.User);
        navigate(`${Routing.Profile}`);
        console.log(`CustomerInfo after purchase: ${customerInfo}`);
      } catch (e) {
        if (e instanceof PurchasesError) {
          console.log(`Error performing purchase: ${e}`);
          // TODO: We should display an error here.
        } else {
          console.error(`Unknown error: ${e}`);
        }
      }
    },
    [offerings?.all, purchases, queryClient, navigate]
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const planPackageId = queryParams.get("planPackageId");

    if (planPackageId && offerings) {
      handleStartPurchase(planPackageId);
    }
  }, [offerings, handleStartPurchase]);

  return (
    <PayWallPresentation
      offerings={offerings}
      onLogout={handleLogout}
      onStartPurchase={handleStartPurchase}
    />
  );
};
