import { useQuery } from "react-query";
import { Queries } from "../../../constants/queries";
import { getCompanyTrucks } from "./getCompanyTrucks";
import { TCompanyTrucks } from "./types";
import { t } from "i18next";
import { translations } from "../../../i18n/translations";
import { useToast } from "@chakra-ui/react";

type Props = {
  companyId?: number | null;
};

export const useCompanyTrucks = ({ companyId }: Props) => {
  const toast = useToast();
  return useQuery<TCompanyTrucks>(
    [Queries.Truck(companyId)],
    getCompanyTrucks(companyId!),
    {
      enabled: !!companyId,
      staleTime: Infinity,
      cacheTime: Infinity,
      onError: () => {
        toast({
          title: "Error",
          description: t<string>(translations.errors.trucks_error),
          status: "error",
          isClosable: true,
          position: "top-right",
        });
      },
    }
  );
};
