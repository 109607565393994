import { TStatus } from "../../../types/common/Api";

export type TPropertyContact = {
  propertyContactId: number;
  jobId: number;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
};

export type TContractor = {
  firstName: string;
  lastName: string;
  userId: number;
  email?: string;
  phone?: string;
  profilePicUrl?: string;
  jobTitle?: string;
  rating?: number;
};

export type THomeowner = {
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  company: string | null;
  companyId: string | null;
  email: string | null;
  firstName: string | null;
  inviteCode: string | null;
  isPlumbingLicenseNotRequired: boolean | null;
  isSepticLicenseNotRequired: boolean | null;
  isStripeComplete: boolean;
  jobTitle: string | null;
  lastName: string | null;
  phoneNumber: string | null;
  plumbingLicense: string | null;
  profilePicUrl: string | null;
  properties: string | null;
  roles: string[];
  septicSystemInspectionsLicense: string | null;
  state: string | null;
  userId: number;
  userRating: number | null;
  userTypeId: number;
  zip: string | null;
};

export type TJob = {
  contractor?: TContractor;
  jobId: number;
  jobTypeId: number;
  jobStatusId: number;
  jobStatus: string;
  propertyUse: boolean;
  zip: string;
  lat: number;
  lng: number;
  jobReasonIds: number[];
  relatedServiceOptionIds: number[];
  relatedJobIds: number[];
  residentialInfoNumberOfBedrooms: number | string | null;
  residentialInfoNumberOfCurrentResidents: string | null;
  residentialInfoLastDateOfOccupancy: string | null;
  commercialInfoTypeOfEstablishment: string | null;
  dateCompleted: string | null;
  dateStarted: string | null;
  dateCancelled: string | null;
  commercialCapacity: string | null;
  commercialInfoGdpRange: string | null;
  residentialCapacity: number | null;
  residentialInfoGdpRange: string | null;
  commercialInfoLastDateOfUse: string | null;
  lastPumpOutId: number | null;
  jobInformationId: number | null;
  jobInformationComment: string | null;
  serviceOptionIds: number[];
  propertyInfoAgeOfSepticSystem: string | null;
  propertyInfoIsThereMoreThanOneSepticId: string | null;
  propertyInfoAreThereMultipleStructureId: string | null;
  propertyInfoSepticTankLocation: string | null;
  propertyInfoSepticSystemDesign: string | null;
  propertyInfoSeasonUse: string | null;
  projectAddressStreet: string | null;
  projectAddressCity: string | null;
  projectAddressState: string | null;
  projectAddressZip: string | null;
  scheduleType: string | null;
  calendarDayTime: null;
  calendarDateFrom: string | null;
  calendarDateTo: string | null;
  flexibleDateRange: number | null;
  jobScheduleFlexibleMonths: string[];
  userId: number;
  totalAmountPaid: number;
  propertyContacts?: TPropertyContact[];
  homeowner: THomeowner;
  generatedReports?: [];

  jobTypeName?: string | null;
};

export type TJobsRaw = {
  data: { jobs: TJob[]; totalJobsCount: number };
  status: TStatus;
};

export type TJobs = { jobs: TJob[]; totalJobsCount: number };

export enum JobStatus {
  Available = "available",
  InProgress = "in-progress",
  Accepted = "accepted",
  Cancelled = "cancelled",
  Draft = "draft",
  Completed = "completed",
}
