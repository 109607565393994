import {
  Flex,
  Modal,
  ModalBody,
  // ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Image,
  Heading,
  Link,
  Box,
} from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";
import { translations } from "../../i18n/translations";
import TickCircleImg from "../../assets/tick-circle.png";
import PlayStoreImg from "../../assets/google-play.png";
import AppStoreImg from "../../assets/app-store.png";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const StartupModalPresentation = (props: Props) => {
  const { isOpen, onClose } = props;
  const screen = translations.screens.profile.startupModal;
  return (
    <>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          height="auto"
          maxWidth={{ base: "90%", md: "502px" }}
          mx="auto"
          p={4}
        >
          <ModalHeader mt={4} px={6}></ModalHeader>
          <ModalBody mb={5} px={{ base: 4, md: 12 }}>
            <Flex align="center" flexDirection="column">
              <Flex justifyContent="center">
                <Image src={TickCircleImg} textAlign="center" width="126px" />
              </Flex>
              <Heading
                color="black"
                fontSize={{ base: "lg", md: "2xl" }}
                marginY="20px"
                textAlign="center"
              >
                {t<string>(screen.heading)}
              </Heading>
              <Flex
                flexDirection="column"
                justifyContent="center"
                px={2}
                textAlign="center"
              >
                <Text
                  color="#3C4257"
                  fontSize={{ base: "14px", md: "18px" }}
                  fontWeight="400"
                  lineHeight="21px"
                >
                  {t<string>(screen.content1)}
                </Text>
                <br />
                <Text
                  color="#3C4257"
                  fontSize={{ base: "14px", md: "18px" }}
                  fontWeight="400"
                  lineHeight="21px"
                >
                  {t<string>(screen.content2)}
                </Text>
              </Flex>
              <Flex
                flexWrap="wrap"
                gap={4}
                justifyContent="center"
                marginY="20px"
              >
                <Box>
                  <Link
                    href="https://play.google.com/store/apps/details?id=com.nationalonsite.proapp"
                    isExternal
                  >
                    <Image src={PlayStoreImg} width="150px" />
                  </Link>
                </Box>
                <Box>
                  <Link
                    href="https://apps.apple.com/us/app/national-onsite/id1625552541"
                    isExternal
                  >
                    <Image mt="4px" src={AppStoreImg} width="150px" />
                  </Link>
                </Box>
              </Flex>
              <Flex justifyContent="center" mt="10px">
                <Link
                  _hover={{ textDecoration: "none" }}
                  color="#4C37E9"
                  fontSize={{ base: "16px", md: "18px" }}
                  fontWeight="500"
                  textDecoration="none"
                  onClick={onClose}
                >
                  {t<string>(screen.continueDashboard)}
                </Link>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
