import {
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  // Spacer,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { IoCheckmarkCircleOutline as CheckIcon } from "react-icons/io5";
import { PricingCardProps } from "../PayWall.types";
import "@fontsource/manrope";

export const PricingCard = (props: PricingCardProps) => {
  const { packageData, onStartPurchase } = props;
  const features = packageData?.features.en
    .split(",")
    .map((item: string) => item.trim());

  return (
    <Flex
      border="1px"
      borderColor="gray.200"
      borderRadius="16px"
      boxShadow="0px 4px 6px -2px rgba(16, 24, 40, 0.03), 
      0px 12px 16px -4px rgba(16, 24, 40, 0.08);"
      flexDirection="column"
      minHeight="482px"
      minWidth="328px"
      mt={2}
    >
      <Flex
        alignItems="center"
        gap="2"
        justifyContent="space-between"
        minWidth="328px"
        p={4}
      >
        <Text
          color="gray.600"
          flex="1"
          fontFamily="Manrope"
          fontSize="18px"
          fontWeight="700"
          gap="2"
          textAlign="center"
        >
          {packageData?.name.en}
        </Text>
        {/* <Spacer /> */}
        {packageData?.isPopular && (
          <Box
            backgroundColor="#F8F5FF"
            borderRadius={999}
            gap="2"
            height="24px"
            padding="2px"
            textAlign="center"
            width="73px"
          >
            <Text color="#4C37E9" fontSize="12px" fontWeight={700}>
              Popular
            </Text>
          </Box>
        )}
      </Flex>
      <Flex gap="1" justifyContent="center" paddingX={4}>
        <Text
          color="gray.700"
          fontFamily="Manrope"
          fontSize="48px"
          fontWeight="700"
          letterSpacing="-1px"
          lineHeight="52.8px"
        >
          {packageData?.price.en}
        </Text>
        <Text
          color="gray.600"
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="600"
          mt="24px"
        >
          {packageData?.priceType.en}
        </Text>
      </Flex>
      <Flex
        color="#8792A2"
        fontFamily="Manrope"
        fontSize="14px"
        fontWeight={600}
        justifyContent="center"
      >
        <Text>{packageData?.billingType.en}</Text>
      </Flex>
      <Flex
        color="#475467"
        fontFamily="Manrope"
        fontSize="16px"
        fontWeight={600}
        justifyContent="center"
        paddingX={4}
        pt="3"
      >
        <Text>{packageData?.description.en}</Text>
      </Flex>
      <Flex justifyContent="center" mt="25px">
        <Button
          // borderColor="#E9D7FE"
          // boxShadow="0px 1px 2px 0px #1018280D"
          // color="#4C37E9"
          colorScheme="nosPurple"
          minHeight="48px"
          minWidth="264px"
          variant="outline"
          onClick={() => onStartPurchase(packageData?.planPackageId)}
        >
          Upgrade to {packageData?.name.en}
        </Button>
      </Flex>
      <Divider mt="30px" />
      <Flex p={4}>
        <Text color="Gray/900" fontSize="16px" fontWeight={700}>
          FEATURES
        </Text>
      </Flex>
      {features.map((feature: any, index: number) => (
        <Flex key={index} gap="1" paddingX={4} paddingY={2}>
          <Icon as={CheckIcon} color="#33B550" fontSize="2xl" />
          <Text color="Gray/600" fontSize="16px" fontWeight={400}>
            {feature}
          </Text>
        </Flex>
      ))}
      <Flex mb="50px"></Flex>
    </Flex>
  );
};
