import { PortalsContextType } from "../../contexts/PortalsContext";
import { TStatics } from "../../api/modules/Statics/types";
import { TJob } from "../../api/modules/Jobs/types";
import { TModalControls } from "../../contexts/ModalsContext/ModalsContext";

export type PresentationProps = {
  pageTitleRef: PortalsContextType["pageTitleRef"];
  breadcrumbsRef: PortalsContextType["breadcrumbsRef"];
  isLoading: boolean;
  job?: TJob;
  statics?: TStatics;
  assignJobModal: TModalControls;
  cancelJobModal: TModalControls;
};

export enum ResidentialCapacity {
  DesignFlow = 1,
  TankSize = 2,
  Bedrooms = 3,
}

export enum PropertyType {
  Commercial = 1,
  Residential = 2,
  Both = 3,
}

export enum ReportType {
  SepticFacts = 1,
  Title5Inspection = 2,
  Pumpout = 3,
  SepticPumpout = 4,
  Repair = 5,
  Maintenance = 6,
}
