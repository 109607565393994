import { MakeOfferModalPresentation } from "./MakeOfferModal.presentation";
import React, { useContext, useEffect, useState } from "react";
import { ModalsContext } from "../../../../../contexts/ModalsContext";
import { useForm } from "react-hook-form";
import { PostTruckSteps, MakeOfferFormType } from "./MakeOfferModal.types";
import { useQuoteJob } from "../../../../../api/modules/UpdateQuoteJob";
import { useToast } from "@chakra-ui/react";
import { translations } from "../../../../../i18n/translations";
import { t } from "i18next";
import { useJobQuote } from "../../../../../api/modules/GetJobQuote";
import { Queries } from "../../../../../constants/queries";
import { useQueryClient } from "react-query";

type Props = {
  makeOfferData?: Partial<MakeOfferFormType> | undefined;
};

export const MakeOfferModal = (props: Props) => {
  const { makeOfferData } = props;
  const toast = useToast();
  const screen = translations.screens.makeOfferModal;
  const { makeOfferModal } = useContext(ModalsContext);
  const [step] = useState<PostTruckSteps>(PostTruckSteps.TruckForm);
  const { mutateAsync: mutateAsyncInvite, isLoading: isLoadingInviteRequest } =
    useQuoteJob();
  const {
    handleSubmit: handleSubmitInvite,
    register: registerInvite,
    formState: { errors: errorsInvite },
  } = useForm<MakeOfferFormType>();

  const [makeOffer, setMakeOffer] = useState<MakeOfferFormType | undefined>(
    undefined
  );

  const { data: jobQuote } = useJobQuote(makeOfferData?.jobId);

  useEffect(() => {
    if (jobQuote && makeOfferData) {
      setMakeOffer({
        jobId: makeOfferData.jobId ?? 0,
        price: jobQuote.jobItems?.[0]?.price ?? 0,
        details: jobQuote.jobItems?.[0]?.itemDescription ?? "",
        jobQuoteStatusId: 2,
      });
    } else if (jobQuote == null) {
      setMakeOffer({
        jobId: makeOfferData?.jobId ?? 0,
        price: null,
        details: "",
        jobQuoteStatusId: 2,
      });
    }
  }, [jobQuote, makeOfferData]);

  const queryClient = useQueryClient();

  const onSubmitInvite = async (data: MakeOfferFormType) => {
    await handleSubmitInvite(async () => {
      await mutateAsyncInvite(
        {
          jobId: makeOfferData?.jobId ?? null,
          JobQuoteId: jobQuote?.jobQuoteId ?? null,
          jobQuoteStatusId: 2, //2=Sent
          jobItems: [
            {
              itemDescription: data.details ?? null,
              price: data.price ?? null,
              itemName: makeOfferData?.jobTypeName ?? null,
              quantity: 1,
            },
          ],
        },
        {
          onSuccess: () => {
            toast({
              title: t<string>(screen.success),
              description: t<string>(screen.successMassage),
              position: "top-right",
              status: "success",
              isClosable: true,
              variant: "subtle",
              containerStyle: { color: "#067647" },
            });
            queryClient.invalidateQueries(Queries.JobQuote(data.jobId));
            makeOfferModal.onClose();
          },
        }
      );
    })();
  };

  const onClose = async () => {
    makeOfferModal.onClose();
  };

  return (
    <MakeOfferModalPresentation
      initialValues={makeOffer}
      inviteErrors={errorsInvite}
      inviteRegister={registerInvite}
      isLoadingInviteRequest={isLoadingInviteRequest}
      isOpen={makeOfferModal.isOpen}
      step={step}
      onClose={onClose}
      onInvite={onSubmitInvite}
    />
  );
};
