import { useMutation, useQueryClient } from "react-query";
import { useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { postCompanyTruck } from "./postCompanyTruck";
import { TData, TPostTruckBody } from "./types";
import { translations } from "../../../i18n/translations";
import { useUser } from "../User";
import { Queries } from "../../../constants/queries";

export const useCompanyTruck = () => {
  const { data: userData } = useUser();
  const companyId = userData?.companyId;
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation<TData, unknown, TPostTruckBody>(
    (payload) => postCompanyTruck(payload, companyId!),
    {
      onError: () => {
        toast({
          title: "Error",
          description: t<string>(translations.errors.invite_team_member_error),
          status: "error",
          position: "top-right",
        });
      },
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries([Queries.Truck(companyId)]);
        queryClient.setQueryData(
          [Queries.Truck(companyId), variables.truckId],
          data
        );
      },
    }
  );
};
